@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: Optima, Segoe, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clickMe {
  color: white;
  animation-name: colorMe;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes colorMe {
  0% {color: white; font-size: 30px;}
  50% {color: rgba(255, 255, 255, 0.338); font-size: 20px;}
  100% {color: white; font-size: 30px;}
}